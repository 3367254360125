export const releaseData = [
    {
        hashid: "2xyut",
        version: "🎉 Big News: AI-Powered Features Have Arrived in Zircly! 🎉 Version 1.7.1",
        description:
            "We’re thrilled to announce the latest Zircly update, packed with cutting-edge AI-powered tools and an all-new Community Module UI! We’ve integrated OpenAI to supercharge your Zircly experience, making content creation, rewriting, and summarizing easier, faster, and more engaging. Here’s a look at what’s new:",
        release_date: "25 Sep 2024",
        release_detail: [
            {
                "title": "🚀 Rewrite with AI",
                "description": `<p>Say goodbye to writer’s block! Whether you’re revamping your entire post or polishing a specific section, our AI-powered <strong>Rewrite with AI</strong> feature is here to help you:</p>
    <ul>
        <li><strong>Rewrite Entire Posts:</strong> Craft your title and body, then simply hit 'Rewrite' for an instant upgrade.</li>
        <li><strong>Select & Rewrite:</strong> Choose specific lines in your post body and let AI refine them with ease.</li>
        <li><strong>Rewrite with Images:</strong> Now you can seamlessly add images to your posts and let the AI rewrite content around them.</li>
        <li><strong>Rewrite with Emojis:</strong> Express more with fewer words! Add emojis, and AI will keep them in your rewritten content for extra personality.</li>
    </ul>
    <p>✨ <em>Start rewriting with AI today and watch your content transform!</em> ✨</p>`,
                "type": "New Feature",
                "doable": "true",
                "attachment": "rewrite.jpg"
            },
            {
                "title": "🤖 Write with AI: Your New Assistant",
                "description": `<p>Meet <strong>Zircly’s very own AI assistant</strong>, powered by OpenAI! You can interact with it just like ChatGPT to <strong>generate engaging content, answer questions, and improve your writing</strong>. Get instant inspiration or let the AI help you brainstorm new ideas:</p>
    <ul>
        <li>Need blog ideas or help writing posts? <strong>Just ask!</strong></li>
        <li>Need answers to tricky questions or complex topics? <strong>The assistant’s got your back!</strong></li>
    </ul>
    <p><strong>Example prompts you can use:</strong></p>
    <ul>
        <li><em>"I want to write a blog about how Secret Santa improves office morale. Can you help?"</em></li>
        <li><em>"Write a 300-word blog about tax fraud and its impact on society."</em></li>
    </ul>
    <p>🔥 <em>Writing has never been this easy! Say hello to your new AI assistant!</em> 🔥</p>`,
                "type": "New Feature",
                "doable": "true",
                "attachment": "WriteAI.png"
            },
            {
                "title": "📝 Summarize with AI",
                "description": `<p>Tired of reading long posts? <strong>AI Summarize</strong> is here! Click the "Summarize" button on any post, and let the AI generate a concise, easy-to-read summary. Best of all, the summary will stay updated if the post gets edited!</p>
    <p>🔍 <em>Get the key points in seconds and switch between full content and the summary whenever you like.</em> 🔍</p>

    <h2>🌟 New Community Module Look & Feel</h2>
    <p>We’ve completely <strong>redesigned the Community Module UI</strong> to make your Zircly experience more enjoyable:</p>
    <ul>
        <li><strong>Post Listing:</strong> Browse posts in 3 awesome modes – <strong>Top</strong> (trending posts), <strong>Recent</strong> (latest posts), and <strong>Unseen</strong> (posts you haven’t viewed yet).</li>
        <li><strong>Post Activity:</strong> Stay updated on post engagements like <strong>likes</strong>, <strong>comments</strong>, and <strong>reactions</strong>, with fun notifications like: <em>"Karthik loved this post"</em>, or <em>"Karthik commented on this post!"</em></li>
    </ul>
    <p>💥 <em>Dive into the new Community Module and experience Zircly like never before!</em> 💥</p>`,
                "type": "New Feature",
                "doable": "true",
                "attachment": "summarize.jpg"
            },
            {
                "title": "🌟 New Community Module Look & Feel",
                "description": `<p>We’ve completely <strong>redesigned the Community Module UI</strong> to make your Zircly experience more enjoyable:</p>
    <ul>
        <li><strong>Post Listing:</strong> Browse posts in 3 awesome modes – <strong>Top</strong> (trending posts), <strong>Recent</strong> (latest posts), and <strong>Unseen</strong> (posts you haven’t viewed yet).</li>
        <li><strong>Post Activity:</strong> Stay updated on post engagements like <strong>likes</strong>, <strong>comments</strong>, and <strong>reactions</strong>, with fun notifications like: <em>"Karthik loved this post"</em>, or <em>"Karthik commented on this post!"</em></li>
    </ul>
    <p>💥 <em>Dive into the new Community Module and experience Zircly like never before!</em> 💥</p>`,
                "type": "Improvement",
                "doable": "true",
                "attachment": "CommunityLook.png"
            },
            {
                "title": "🎉 Animated Post Reactions!",
                "description": `<p>Reactions just got more fun! Express yourself with <strong>animated reactions</strong> like <strong>Like, Appreciate, Love, Celebrate, Insightful, and Laugh</strong>. These lively animations make every interaction more exciting!</p>`,
                "type": "Improvement",
                "doable": "true",
                "attachment": "Reaction.png"
            },
            {
                "title": "✨ Sleek New Post Create/Update Form",
                "description": `<p>We’ve streamlined the way you create and update posts. The new <strong>Inline Toolbar</strong> pops up when you select text, making formatting easier than ever. <em>It’s fast, intuitive, and works like magic</em>, just like LinkedIn!</p>`,
                "type": "Improvement",
                "doable": "true",
                "attachment": "PostAdd.png"
            },
            {
                "title": "🎂 Celebrations! (Anniversaries & Birthdays)",
                "description": `<p>Zircly now celebrates YOU! Whether it’s a <strong>birthday</strong> or a <strong>work anniversary</strong>, our new <strong>Celebrations card</strong> highlights users celebrating their big day. You can send them heartfelt wishes, which are displayed in a <strong>fun, animated post</strong> when they log in.</p>`,
                "type": "New Feature",
                "doable": "true",
                "attachment": "wish.png"
            }
        ],
        created_by: 0,
    }
];
