<template>
    <div class="release-list release">
        <div class="m-0 p-0">
            <b-card class="my-2" v-for="release in releaseData"> 
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="note-header mb-0">{{ release.version }}</h3>
                    <span class="date">{{ release.release_date }}</span>
                </div>
                <p class="my-2">
                    {{release.description  }}
                </p>
                <b-button variant="primary" :to="`/release-detail/${release.hashid}`" pill>Full Details</b-button>
            </b-card>
        </div>
    </div>
</template>
<script>
import store from "@/store";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BSidebar,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { releaseData } from "../../assets/jsons/release_notes.js"
Vue.use(VueCookies);
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VBTooltip,
    BBadge,
    BProgress,
    BProgressBar,
    BSidebar,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      releaseData,
      isBusy: false,
      links: [],
      new_link: {
        name: "",
        description: "",
        link: "",
      },

      selected_link: {
        hashid: "",
        name: "",
        description: "",
        link: "",
      },
      userData: this.$cookies.get("userData"),
      release_notes:[],
    };
  },
  created(){
  }
};
</script>